<script>
export default {
  name: 'nav',
  computed: {
    sidebarItems() {
      return [{
        _name: 'CSidebarNav',
        _children:
            [
              {
                _name: 'CSidebarNavItem',
                name: this.$t('menu.dashboard'),
                to: '/dashboard',
                icon: 'cil-speedometer',
                badge: {
                  color: 'danger'
                }
              },
              {
                _name: 'CSidebarNavItem',
                name: 'INTERNET',
              },
              {
                _name: 'CSidebarNavDropdown',
                name: 'Necrologi',
                route: '/manifesti',
                icon: 'cil-contact',
                items: [
                  {
                    name: 'Lista Necrologi',
                    to: '/necrologi/lista'
                  },
                  {
                    name: 'Lista Manifesti',
                    to: '/manifesti/lista',
                  },
                ]
              },
              {
                _name: 'CSidebarNavDropdown',
                name: 'Sito',
                route: '/sito',
                icon: 'cil-devices',
                items: [
                  {
                    name: 'Homepage',
                    to: '/sito/homepage'
                  },
                  {
                    name: 'Chi Siamo',
                    to: '/sito/chisiamo'
                  },
                  {
                    name: 'Contatti',
                    to: '/sito/contatti'
                  },
                  {
                    name: 'Casa Funeraria',
                    to: '/sito/listacasafuneraria'
                  },
                  {
                    name: 'FAQ',
                    to: '/sito/faq'
                  }
                ]
              },
              {
                _name: 'CSidebarNavItem',
                name: 'TOTEM',
              },
              {
                _name: 'CSidebarNavDropdown',
                name: 'Totem',
                route: '/totem',
                icon: 'cilScreenSmartphone',
                items: [
                  {
                    name: 'Lista Totem',
                    to: '/totem/lista'
                  }
                ]
              },
            ]
      }]
    },

    sidebarItemsLimited() {
      return [{
        _name: 'CSidebarNav',
        _children:
            [

              {
                _name: 'CSidebarNavItem',
                name: this.$t('menu.dashboard'),
                to: '/dashboard',
                icon: 'cil-speedometer',
                badge: {
                  color: 'danger'
                }
              },
              {
                _name: 'CSidebarNavItem',
                name: 'INTERNET',
              },
              {
                _name: 'CSidebarNavDropdown',
                name: 'Necrologi',
                route: '/manifesti',
                icon: 'cil-contact',
                items: [
                  {
                    name: 'Lista Necrologi',
                    to: '/necrologi/lista'
                  },
                  {
                    name: 'Lista Manifesti',
                    to: '/manifesti/lista',
                  },
                ]
              },
              {
                _name: 'CSidebarNavDropdown',
                name: 'Sito',
                route: '/sito',
                icon: 'cil-devices',
                items: [
                  {
                    name: 'Homepage',
                    to: '/sito/homepage'
                  },
                  {
                    name: 'Chi Siamo',
                    to: '/sito/chisiamo'
                  },
                  {
                    name: 'Contatti',
                    to: '/sito/contatti'
                  },
                  {
                    name: 'FAQ',
                    to: '/sito/faq'
                  }
                ]
              },
              {
                _name: 'CSidebarNavItem',
                name: 'TOTEM',
              },
              {
                _name: 'CSidebarNavDropdown',
                name: 'Totem',
                route: '/totem',
                icon: 'cilScreenSmartphone',
                items: [
                  {
                    name: 'Lista Totem',
                    to: '/totem/lista'
                  }
                ]
              },
            ]
      }]
    }
  }
}
</script>
