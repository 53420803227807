<template>
  <CSidebar
      :minimize="minimize"
      unfoldable
      :show="show"
      @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img
          src="@/assets/logo/logo_dashboard_sfondo_scuro.svg"
          class="c-sidebar-brand-full"
          height="35"
          alt="">

      <img
          src="@/assets/logo/logo24bianco.png"
          class="c-sidebar-brand-minimized"
          height="20"
          width="30"
          alt="">
    </CSidebarBrand>

    <!-- Rendering Menu -->
    <CRenderFunction
        v-if="agenzia.tipologia_abbonamento === 3"
        flat
        :contentToRender="sidebarItems"
    />

    <CRenderFunction
        v-else flat
        :contentToRender="sidebarItemsLimited"
    />
    <!-- -->

    <CSidebarMinimizer
        class="c-d-md-down-none"
        @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import SidebarItems from './SidebarItems'
import {mapGetters} from "vuex";

export default {
  name: 'TheSidebar',
  extends: SidebarItems,
  /**
   * ABBONAMENTI_AZIENDE = (
   *     (0, 'Gratuita'),
   *     (1, 'Premium'),
   *     (2, 'Premium Azienda'),
   *     (3, 'Advanced'),
   * )
   */
  computed: {
    ...mapGetters('agenzia', [
      'agenzia',
    ]),
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>

